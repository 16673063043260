import { underJest } from "../utilites/Spinner/SpinnerContext";
import { realtimeDB } from "./Firebase/Firebase";

export async function textValidator(
  minLength,
  maxLength,
  control,
  passedCallback,
  failedCallback,
  itemObject,
  okCSSClass,
  failedCSSClass
) {
  let result = false;
  itemObject[control.id] = "";
  itemObject.changed = true;

  if (
    control.value &&
    control.value.length > minLength &&
    control.value.length < maxLength
  ) {
    result = true;
  }

  removeCSSClass(control, okCSSClass);
  removeCSSClass(control, failedCSSClass);

  itemObject[control.id] = control.value;

  if (!result) {
    let errorMessage = `Please provide value with length more than ${minLength} and less than ${maxLength} symbols`;
    failedCallback(itemObject, errorMessage);
    addCSSClass(control, failedCSSClass);
    return;
  }

  passedCallback(itemObject);
  addCSSClass(control, okCSSClass);
}

export function removeCSSClass(control, CSSName) {
  let CSSClasses = control.className.split(" ");
  CSSClasses = CSSClasses.filter((className) => className !== CSSName);
  control.className = CSSClasses.join(" ");
}

export function addCSSClass(control, CSSName) {
  let CSSClasses = control.className.split(" ");
  CSSClasses.push(CSSName);
  control.className = CSSClasses.join(" ");
}

/* istanbul ignore next */
export async function fullnameValidator(fullname, id) {
  let result = await realtimeDB
    .ref("phonebook")
    .orderByChild("fullname")
    .equalTo(fullname)
    .once("value")
    .then(function (dataSnapshot) {
      return dataSnapshot.val();
    });

  if (
    result &&
    Object.keys(result).length > 0 &&
    id !== result[Object.keys(result)[0]].id
  ) {
    return true;
  }

  return false;
}

export async function emailValidator(
  minLength,
  maxLength,
  control,
  passedCallback,
  failedCallback,
  itemObject,
  okCSSClass,
  failedCSSClass
) {
  let result = false;
  itemObject[control.id] = "";
  itemObject.changed = true;

  if (
    control.value &&
    control.value.length > minLength &&
    control.value.length < maxLength
  ) {
    result = true;
  }

  const emailParts = control.value.split("@");

  if (result && emailParts.length !== 2) {
    let errorMessage = `Please provide correct e-mail address`;
    failedCallback(itemObject, errorMessage);
    addCSSClass(control, failedCSSClass);
    return;
  }

  const endsWithDot = emailParts.filter((element) =>
    /\.$|\+$|-$/gm.test(element)
  );

  if (result && endsWithDot.length > 0) {
    let errorMessage = `Please provide correct e-mail address`;
    failedCallback(itemObject, errorMessage);
    addCSSClass(control, failedCSSClass);
    return;
  }

  const emailPartsByDots = control.value.replace("@", ".").split(".");
  const emailPartsByDefis = control.value.split("-");
  const emailPartsByPlus = control.value.split("+");
  const emailPartsByUnderscore = control.value.split("_");

  const twoDots = emailPartsByDots.filter((element) => element === "");
  const twoDefis = emailPartsByDefis.filter((element) => element === "");
  const twoPlus = emailPartsByPlus.filter((element) => element === "");
  const twoUnderscore = emailPartsByUnderscore.filter(
    (element) => element === ""
  );

  if (
    result &&
    (twoDots.length > 0 ||
      twoDefis.length > 0 ||
      twoPlus.length > 0 ||
      twoUnderscore.length > 0)
  ) {
    result = false;
  }

  const domainPart = emailPartsByDots[emailPartsByDots.length - 1];

  if (result && (domainPart.length < 2 || /\W+/g.test(domainPart))) {
    result = false;
  }

  const notAllowedForUsername = new RegExp("[^a-zA-Z0-9_+-.]+", "gm");
  const notAllowedForDomain = new RegExp("[^a-zA-Z0-9_\\-.]+", "gm");

  if (
    result &&
    (notAllowedForUsername.test(emailParts[0]) ||
      notAllowedForDomain.test(emailParts[1]))
  ) {
    result = false;
  }

  removeCSSClass(control, okCSSClass);
  removeCSSClass(control, failedCSSClass);

  itemObject[control.id] = control.value;

  if (!result) {
    let errorMessage = `Please provide correct e-mail address`;
    failedCallback(itemObject, errorMessage);
    addCSSClass(control, failedCSSClass);
    return;
  }

  if (underJest) {
    passedCallback(itemObject);
    addCSSClass(control, okCSSClass);
    return;
  }

  result = await realtimeDB
    .ref("phonebook")
    .orderByChild("email")
    .equalTo(control.value)
    .once("value")
    .then(function (dataSnapshot) {
      return dataSnapshot.val();
    });

  if (
    result &&
    Object.keys(result).length > 0 &&
    itemObject.id !== result[Object.keys(result)[0]].id
  ) {
    let errorMessage = `Provided email is used for other record stored in DataBase`;
    failedCallback(itemObject, errorMessage);
    addCSSClass(control, failedCSSClass);
    return;
  }

  passedCallback(itemObject);
  addCSSClass(control, okCSSClass);
}

export function birthdayValidator(
  control,
  passedCallback,
  failedCallback,
  itemObject,
  okCSSClass,
  failedCSSClass
) {
  let result = false;
  itemObject[control.id] = "";
  itemObject.changed = true;

  if (control.value && control.value.length === 10) {
    result = true;
  }

  const testDate = new Date(control.value);

  if (result && Date.now() < testDate.getTime()) {
    result = false;
  }

  removeCSSClass(control, okCSSClass);
  removeCSSClass(control, failedCSSClass);

  if (!result) {
    let errorMessage = `Please provide correct date from past`;
    failedCallback(itemObject, errorMessage);
    addCSSClass(control, failedCSSClass);
    return;
  }

  itemObject[control.id] = control.value;
  passedCallback(itemObject);
  addCSSClass(control, okCSSClass);
}

export async function phoneValidator(
  control,
  passedCallback,
  failedCallback,
  itemObject,
  okCSSClass,
  failedCSSClass
) {
  let result = false;
  itemObject[control.id] = "";
  itemObject.changed = true;

  let providedValue = control.value;

  providedValue = providedValue.replace(/\+|\(|\)|-|([^0-9])/gm, "");

  let providedValueArray = providedValue.split("");

  if (providedValueArray.length > 0) {
    providedValueArray.splice(0, 0, "+");
  }

  if (providedValueArray.length >= 5) {
    providedValueArray.splice(4, 0, "(");
  }

  if (providedValueArray.length >= 8) {
    providedValueArray.splice(7, 0, ")");
  }

  if (providedValueArray.length >= 12) {
    providedValueArray.splice(11, 0, "-");
  }

  if (providedValueArray.length >= 15) {
    providedValueArray.splice(14, 0, "-");
  }

  providedValue = providedValueArray.join("").substr(0, 17);

  itemObject[control.id] = providedValue;

  if (control.value && providedValue.length === 17) {
    result = true;
  }

  removeCSSClass(control, okCSSClass);
  removeCSSClass(control, failedCSSClass);

  if (!result) {
    let errorMessage = `Please provide correct phonenumber`;
    failedCallback(itemObject, errorMessage, providedValue);
    addCSSClass(control, failedCSSClass);
    return;
  }

  if (underJest) {
    passedCallback(itemObject);
    addCSSClass(control, okCSSClass);
    return;
  }

  result = await realtimeDB
    .ref("phonebook")
    .orderByChild("phonenumber")
    .equalTo(providedValue)
    .once("value")
    .then(function (dataSnapshot) {
      return dataSnapshot.val();
    });

  if (
    result &&
    Object.keys(result).length > 0 &&
    itemObject.id !== result[Object.keys(result)[0]].id
  ) {
    let errorMessage = `Provided phonenumber is used for other record stored in DataBase`;
    failedCallback(itemObject, errorMessage, providedValue);
    addCSSClass(control, failedCSSClass);
    return;
  }

  itemObject[control.id] = providedValue;
  passedCallback(itemObject, providedValue);
  addCSSClass(control, okCSSClass);
}

export function avatarValidator(
  control,
  passedCallback,
  failedCallback,
  itemObject,
  okCSSClass,
  failedCSSClass
) {
  let result = false;
  itemObject[control.id] = "";

  removeCSSClass(control, okCSSClass);
  removeCSSClass(control, failedCSSClass);

  if (control.value && control.files.length > 0) {
    result = true;
  }

  if (!result) {
    let errorMessage = `Please select image with avatar`;
    addCSSClass(control, failedCSSClass);
    failedCallback(itemObject, errorMessage);
    return;
  }

  itemObject[control.id] = URL.createObjectURL(control.files[0]);
  itemObject.changed = true;
  addCSSClass(control, okCSSClass);
  passedCallback(itemObject);
}
