export const userPermissionsRecordObject = {
  id: "",
  email: "",
  processed: false,
  granted: false,
  nid: "",
};

const getObject = (newUserPermissionsRecordObject) => {
  const objectId = window.sessionStorage.getItem("uid");
  return Object.assign(newUserPermissionsRecordObject, { id: objectId });
};

export const getNewObject = (emailIn) => {
  const newObject = getObject(Object.create(userPermissionsRecordObject));
  newObject.email = emailIn;
  newObject.nid = new Date().getTime();
  return newObject;
};

export const getEmptyObject = () => {
  return Object.create(userPermissionsRecordObject);
};
