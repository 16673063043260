import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import * as firebaseService from "../../service/Firebase/Firebase";
import { SpinnerContext } from "../../utilites/Spinner/SpinnerContext";

const SignOut = () => {
  const history = useHistory();

  const { setCurrentUser, toggleSpinnerOn, toggleSpinnerOff } =
    useContext(SpinnerContext);

  const signOutFunction = async () => {
    toggleSpinnerOn();
    firebaseService
      .signOut()
      .then(() => {
        window.sessionStorage.removeItem("currentUser");
        window.sessionStorage.removeItem("uid");
        setCurrentUser("");
        toggleSpinnerOff();
        history.push("/");
      })
      .catch((error) => {
        toggleSpinnerOff();
      });
  };

  return (
    <div className="container-fluid text-center mt-5 w-25">
      <button
        type="button"
        id="logoutButton"
        className="btn btn-primary btn-lg btn-block"
        onClick={signOutFunction}
      >
        Sign Out
      </button>
    </div>
  );
};

export default SignOut;
