import React, { useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import * as CONSTANTS from "../../constants/constants";
import { firebaseAuth } from "../../service/Firebase/Firebase";
import { SpinnerContext } from "../../utilites/Spinner/SpinnerContext";
import { realtimeDB } from "../../service/Firebase/Firebase";
import { getEmptyObject } from "../../service/Firebase/userPermissionsFirebaseObject";

const Navigation = (props) => {
  const {
    toggleSpinnerOn,
    toggleSpinnerOff,
    currentUser,
    setCurrentUser,
    authStatus,
    initCompleted,
    setInitCompleted,
    setIsAdmin,
    setPermissionsGranted,
  } = useContext(SpinnerContext);

  useEffect(() => {
    //return
    firebaseAuth.onAuthStateChanged((user) => {
      if (user !== null) {
        let userEmail =
          user.email !== null ? user.email : "anonymous@anonymous.com";
        setCurrentUser(userEmail);

        window.sessionStorage.setItem("currentUser", userEmail);
        window.sessionStorage.setItem("uid", user.uid);
      }
      toggleSpinnerOff();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getPermissionsGranted() {
      toggleSpinnerOn();

      if (!authStatus || currentUser.length === 0) {
        console.info("authStatus: ", authStatus);
        console.info("currentUser: ", currentUser);
        setPermissionsGranted(false);
        toggleSpinnerOff();
        return;
      }

      // const initCheckResult = await realtimeDB
      //   .ref("settings/initdone")
      //   .get()
      //   .then(async function (dataSnapshot) {
      //     return await dataSnapshot.val();
      //   })
      //   .catch((error) => {
      //     return 0;
      //   });

      if (props.initCheckResult > 0 && !props.initCompleted) {
        setInitCompleted(true);
      }

      console.info("currentUser: ", props.currentUser);

      await realtimeDB
        .ref("users")
        .orderByChild("email")
        .equalTo(props.currentUser)
        .get()
        .then(async function (dataSnapshot) {
          return await dataSnapshot.val();
        })
        .then(async (portion) => {
          console.info("portion: ", portion);
          if (!portion) {
            return [];
          }
          Object.keys(portion).forEach((key) => {
            let recordItem = Object.assign(getEmptyObject(), portion[key]);
            setPermissionsGranted(recordItem.granted);
            return;
          });
        })
        .catch((error) => {
          console.info("error: ", error);
          setPermissionsGranted(false);
        });

      await realtimeDB
        .ref("admin")
        .child(window.sessionStorage.getItem("uid"))
        .get()
        .then(async function (dataSnapshot) {
          setIsAdmin(dataSnapshot.exists());
        })
        .catch((error) => {
          return 0;
        });

      toggleSpinnerOff();
    }
    getPermissionsGranted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, authStatus, initCompleted]);

  if (
    (props.currentUser === null || props.currentUser.length === 0) &&
    process.env.REACT_APP_ANONYMOUS_AUTH_ENABLED === "YES"
  ) {
    console.info("do anon auth");
    firebaseAuth.signInAnonymously();
  }

  if (process.env.REACT_APP_NEW_USER === "YES") {
    setPermissionsGranted(true);
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="navbar-header">
        <Link className="navbar-brand" to={CONSTANTS.LANDING} id="startPage">
          Phonebook
        </Link>
      </div>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        {props.currentUser &&
        props.currentUser.length > 0 &&
        !props.permissionsGranted ? (
          <ul className="navbar-nav mr-auto">
            <li className="nav-item nav-link">
              <NavLink
                to={CONSTANTS.REQUEST_PERMISSIONS}
                activeClassName="activeRoute"
                id="requestPermissions"
              >
                Request permissions
              </NavLink>
            </li>

            {!props.initCompleted ? (
              <li className="nav-item nav-link">
                <NavLink
                  to={CONSTANTS.INIT_APPLICATION}
                  activeClassName="activeRoute"
                  id="init"
                >
                  INIT
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
        ) : (
          ""
        )}

        {props.currentUser &&
        props.currentUser.length > 0 &&
        props.permissionsGranted ? (
          <ul className="navbar-nav mr-auto">
            <li className="nav-item nav-link">
              <NavLink
                to={CONSTANTS.PHONEBOOK_LIST}
                activeClassName="activeRoute"
                id="listPhonebook"
              >
                List Phonebook
              </NavLink>
            </li>

            <li className="nav-item nav-link">
              <NavLink
                to={CONSTANTS.PHONEBOOK_ADD}
                activeClassName="activeRoute"
                id="addRecord"
              >
                Add record
              </NavLink>
            </li>

            <li className="nav-item nav-link">
              <NavLink
                to={CONSTANTS.PHONEBOOK_SEARCH}
                activeClassName="activeRoute"
                id="search"
              >
                Search
              </NavLink>
            </li>

            {props.isAdmin ? (
              <li className="nav-item nav-link">
                <NavLink
                  to={CONSTANTS.GRANT_PERMISSIONS}
                  activeClassName="activeRoute"
                  id="grantPermissions"
                >
                  Grant permissions
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
        ) : (
          ""
        )}
        <ul className="navbar-nav nav my-2 my-lg-0">
          {!props.currentUser ? (
            <li className="nav-item nav-link">
              <NavLink
                to={CONSTANTS.AUTH_SIGNIN}
                activeClassName="activeRoute"
                id="signIn"
              >
                Sign In
              </NavLink>
            </li>
          ) : (
            <li className="nav-item nav-link">
              <span className="mr-3">{props.currentUser}</span>
              <NavLink
                to={CONSTANTS.AUTH_SIGNOUT}
                activeClassName="activeRoute"
                id="signOut"
              >
                Sign Out
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
