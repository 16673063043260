import React, { useState, useEffect, useContext } from "react";
import { realtimeDB } from "../../service/Firebase/Firebase";
import { getEmptyObject } from "../../service/Firebase/phonebookFirebaseObject";
import PhonebookItem from "../PhonebookItem/PhonebookItem";
import { NEXT, PREV, RECORDS_PER_PAGE } from "../../constants/constants";
import {
  SpinnerContext,
  underJest,
} from "../../utilites/Spinner/SpinnerContext";
import { NO_RECORDS_MESSAGE } from "../../constants/constants";

export function showNextButton(currentPage, recordsPerPage, recordsTotal) {
  if (underJest() && recordsPerPage === RECORDS_PER_PAGE) {
    return true;
  }

  return (currentPage + 1) * recordsPerPage < recordsTotal;
}

export const showPrevButton = (currentPage, recordsPerPage) => {
  if (underJest() && recordsPerPage === RECORDS_PER_PAGE) {
    return true;
  }

  return currentPage > 0 ? true : false;
};

const PhonebookList = () => {
  const { toggleSpinnerOn, toggleSpinnerOff } = useContext(SpinnerContext);

  const noRecordsMessage = NO_RECORDS_MESSAGE;

  const [records, setRecords] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsTotal, setRecordsTotal] = useState(0);

  const [direction, setDirection] = useState(NEXT);
  const [pageMarkers, setPageMarkers] = useState([]);

  const recordsPerPage = RECORDS_PER_PAGE;

  const goPrevPage = () => {
    toggleSpinnerOn();
    setDirection(PREV);
    setCurrentPage(currentPage - 1);
  };

  const goNextPage = () => {
    toggleSpinnerOn();
    setDirection(NEXT);
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    toggleSpinnerOn();

    async function getPhonebookRecords() {
      const newRecords = [];

      const recordsCnt = await realtimeDB
        .ref("settings/recordsCount")
        .get()
        .then(async function (dataSnapshot) {
          return await dataSnapshot.val();
        })
        .catch((error) => {
          return 0;
        });

      setRecordsTotal(recordsCnt);

      if (recordsCnt === 0) {
        setRecords(newRecords);
        return;
      }

      const indexValue = direction === NEXT ? currentPage - 1 : currentPage;

      const pageMarker = pageMarkers.length > 0 ? pageMarkers[indexValue] : 0;

      const showNextPage = realtimeDB
        .ref("phonebook")
        .orderByChild("nid")
        .startAfter(pageMarker)
        .limitToFirst(recordsPerPage);

      const showPrevPage = realtimeDB
        .ref("phonebook")
        .orderByChild("nid")
        .endAt(pageMarker)
        .limitToLast(recordsPerPage);

      let firebaseQuery =
        direction === NEXT || pageMarker === 0 ? showNextPage : showPrevPage;

      let newPortion = await firebaseQuery
        .get()
        .then(async function (dataSnapshot) {
          return await dataSnapshot.val();
        })
        .then(async (portion) => {
          if (!portion || portion===null) {
            return [];
          }
          Object.keys(portion).forEach((key) => {
            let recordItem = Object.assign(getEmptyObject(), portion[key], {
              newrecord: false,
              changed: false,
            });
            recordItem.avatar = recordItem.imageurl;

            newRecords.push(recordItem);
          });

          return newRecords;
        });

      if (newPortion.length === 0) {
        console.info('here');
        setPageMarkers([]);
        setRecordsTotal(recordsCnt);
        if (currentPage>0){
          setCurrentPage(currentPage - 1);
        }
        toggleSpinnerOff();
      }

      if (newPortion.length === 0 && direction === PREV) {
        setPageMarkers([]);
        setDirection(NEXT);
        setCurrentPage(0);
      }

      if (newPortion.length > 0) {
        setPageMarkers((currentArray) => {
          let arrayForUpdate = currentArray.filter((element, index) => {
            const newSize = direction === NEXT ? currentPage + 1 : currentPage;
            return index <= newSize;
          });

          if (direction === NEXT) {
            arrayForUpdate.push(newPortion[newPortion.length - 1].nid);
          }

          return [...arrayForUpdate];
        });

        setRecords(newPortion);
        toggleSpinnerOff();
      }

      return () => {
        setCurrentPage(0);
        setRecords([]);
      };
    }
    getPhonebookRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div>
      <div>
        <div className="container-fluid text-center mt-5">Phonebook List</div>
        <div className="container">
          <div className="row">
            {records.length > 0
              ? records.map((element, index) => {
                  return (
                    <div className="col-3" key={index}>
                      <PhonebookItem element={element} />
                    </div>
                  );
                })
              : noRecordsMessage}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <div className="mr-5 col-1">
          {showPrevButton(currentPage, recordsPerPage) ? (
            <button
              type="button"
              id="goPrevButton"
              onClick={goPrevPage}
              className="btn btn-info btn-lg btn-block"
            >
              Prev Page
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="ml-5 col-1">
          {showNextButton(currentPage, recordsPerPage, recordsTotal) ? (
            <button
              type="button"
              id="goNextButton"
              onClick={goNextPage}
              className="btn btn-info btn-lg btn-block"
            >
              Next Page
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
export default PhonebookList;
