import React, { useContext } from "react";
import { SpinnerContext } from "../../utilites/Spinner/SpinnerContext";
import { useHistory } from "react-router-dom";

const PhonebookItem = (props) => {
  const history = useHistory();

  const { setPhonebookItem } = useContext(SpinnerContext);

  const element = props.element;

  const editProfile = async (recordItem) => {
    recordItem.avatar = await fetch(recordItem.avatar).then((response) =>
      response.blob()
    );
    setPhonebookItem(recordItem);
    history.push("/phonebook_edit");
  };

  const imageURL =
    element.imageurl !== undefined
      ? element.imageurl
      : process.env.PUBLIC_URL + "portrait_placeholder.jpg";

  return (
    <div className="card border border-dark m-3" key={element.id}>
      <div className="carddivimg">
        <img className="cardimg" src={imageURL} alt="Card cap" />
      </div>
      <div className="card-body text-center">
        <h5 className="card-title">{element.name + " " + element.surname}</h5>
        <h5 className="card-title">{element.phonenumber}</h5>
        <button
          className="btn btn-primary"
          onClick={() => editProfile(element)}
        >
          Go to details
        </button>
      </div>
    </div>
  );
};

export default PhonebookItem;
