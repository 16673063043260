import React, { useState, useEffect, useRef, useContext } from "react";
import {
  textValidator,
  fullnameValidator,
  emailValidator,
  phoneValidator,
  birthdayValidator,
  avatarValidator,
  removeCSSClass,
  addCSSClass,
} from "../../service/validatorsService";
import {
  realtimeDB,
  fileStorage,
  firebaseInstance,
} from "../../service/Firebase/Firebase";
import {
  SpinnerContext,
  underJest,
} from "../../utilites/Spinner/SpinnerContext";
import * as phonebookFirebaseObject from "../../service/Firebase/phonebookFirebaseObject";
import { useHistory } from "react-router-dom";
import Cropper from "react-easy-crop";

const PhonebookRecord = () => {
  const history = useHistory();
  const {
    toggleSpinnerOn,
    toggleSpinnerOff,
    phonebookItem,
    setPhonebookItem,
    currentUser,
  } = useContext(SpinnerContext);

  const [phonebookItemIsValid, setPhonebookItemIsValid] = useState(
    phonebookFirebaseObject.isValid(phonebookItem)
  );

  const [avatarFile, setAvatarFile] = useState(
    process.env.PUBLIC_URL + "portrait_placeholder.jpg"
  );
  const [avatarFileData, setAvatarFileData] = useState("");

  const nameInputRef = useRef(null);
  const surnameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const phonenumberInputRef = useRef(null);
  const birthdayInputRef = useRef(null);
  const avatarInputRef = useRef(null);
  const authorInputRef = useRef(null);

  const inputs = [
    nameInputRef,
    surnameInputRef,
    emailInputRef,
    phonenumberInputRef,
    birthdayInputRef,
    avatarInputRef,
  ];

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setСroppedImage] = useState(null);
  const [cropMode, setCropMode] = useState(false);

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setСroppedImage(await getCroppedImg(avatarFile, croppedAreaPixels));
  };

  const updateAvatarWithCropedImage = () => {
    setAvatarFile(URL.createObjectURL(croppedImage));
    setAvatarFileData(croppedImage);
    phonebookItem.changed = true;
    phonebookItem.avatar = avatarFile;
    phonebookItem.cropped = true;
    setPhonebookItem(phonebookItem);
    setCropMode(false);
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(phonebookItem));
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, crop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 500;
    canvas.height = 500;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpeg");
    });
  };

  useEffect(() => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(phonebookItem));

    if (phonebookItem.newrecord) {
      phonebookItem.author = currentUser;
    } else {
      inputs.forEach((element) => {
        addCSSClass(element.current, "is-valid");
      });

      if (!phonebookItem.cropped) {
        setAvatarFile(URL.createObjectURL(phonebookItem.avatar));
      } else {
        setAvatarFile(phonebookItem.avatar);
      }

      setAvatarFileData(phonebookItem.avatar);

      phonebookItem.changed = false;
    }

    authorInputRef.current.value = phonebookItem.author;

    return () => {
      if (!phonebookItem.newrecord) {
        console.info("cleared");
        const newRecord = phonebookFirebaseObject.getNewObject(
          phonebookItem.author
        );
        setPhonebookItem(newRecord);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [name, setName] = useState(phonebookItem.name);
  const [surname, setSurname] = useState(phonebookItem.surname);
  const [email, setEmail] = useState(phonebookItem.email);
  const [phoneNumber, setPhoneNumber] = useState(phonebookItem.phonenumber);
  const [birthday, setBirthday] = useState(phonebookItem.birthday);

  const [nameFieldErrorMessage, setNameFieldErrorMessage] = useState("");
  const [surnameFieldErrorMessage, setSurnameFieldErrorMessage] = useState("");
  const [fullnameFieldErrorMessage, setFullnameFieldErrorMessage] =
    useState("");
  const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState("");
  const [phoneFieldErrorMessage, setPhoneFieldErrorMessage] = useState("");
  const [birthdayFieldErrorMessage, setBirthdayFieldErrorMessage] =
    useState("");
  const [avatarFieldErrorMessage, setAvatarFieldErrorMessage] = useState("");

  const [formSubmitErrorMessage, setFormSubmitErrorMessage] = useState("");
  const [formSubmitSuccessMessage, setFormSubmitSuccessMessage] = useState("");

  const submitForm = async () => {
    if (phonebookItem.newrecord) {
      await saveData();
    } else {
      await updateData();
    }
  };

  const saveData = async () => {
    toggleSpinnerOn();

    if (underJest()) {
      return;
    }

    const prevAvatarValue = phonebookItem.avatar;
    delete phonebookItem.avatar;

    const storageRef = fileStorage.ref();
    const fileOnStorageRef = storageRef.child(phonebookItem.id);
    await fileOnStorageRef
      .put(avatarFileData)
      .then(() => {
        return fileOnStorageRef.getDownloadURL();
      })
      .then((url) => {
        phonebookItem.imageurl = url;
        console.info("url: ", url);
      })
      .then(() => {
        return realtimeDB
          .ref("phonebook")
          .child(phonebookItem.id)
          .set(phonebookItem);
      })
      .then(() => {
        const updates = {};
        updates[`settings/recordsCount`] =
          firebaseInstance.database.ServerValue.increment(1);
        return realtimeDB.ref().update(updates);
      })
      .then(() => {
        setFormSubmitSuccessMessage("Record has been saved");
        toggleSpinnerOff();
        setTimeout(() => {
          const phonebookItemNew =
            phonebookFirebaseObject.getNewObject(currentUser);
          setPhonebookItem(phonebookItemNew);
          history.push("/phonebook_list");
        }, 1000);
      })
      .catch((error) => {
        setFormSubmitErrorMessage(error);
        phonebookItem.avatar = prevAvatarValue;
        toggleSpinnerOff();
      });
  };

  const updateData = async () => {
    toggleSpinnerOn();

    if (underJest()) {
      return;
    }

    delete phonebookItem.avatar;
    delete phonebookItem.newrecord;

    const storageRef = fileStorage.ref().child(phonebookItem.id);

    await storageRef
      .delete()
      .then(() => {
        console.info("deleted");
      })
      .catch((error) => {
        console.info("error: ", error);
      });

    await storageRef
      .put(avatarFileData)
      .then(() => {
        console.info("saved");
        return storageRef.getDownloadURL();
      })
      .then((url) => {
        phonebookItem.imageurl = url;
        const updates = {};
        updates["/phonebook/" + phonebookItem.id] = phonebookItem;
        return realtimeDB.ref().update(updates);
      })
      .then(() => {
        setFormSubmitSuccessMessage("Record has been updated");
        toggleSpinnerOff();
        setTimeout(() => {
          const phonebookItemNew =
            phonebookFirebaseObject.getNewObject(currentUser);
          setPhonebookItem(phonebookItemNew);
          history.push("/phonebook_list");
        }, 1000);
      })
      .catch((error) => {
        toggleSpinnerOff();
        setFormSubmitErrorMessage(error);
      });
  };

  const resetForm = () => {
    toggleSpinnerOn();

    if (underJest()) {
      return;
    }

    const phonebookItemNew = phonebookFirebaseObject.getNewObject(currentUser);
    setPhonebookItem(phonebookItemNew);

    inputs.forEach((element) => {
      removeCSSClass(element.current, "is-valid");
      removeCSSClass(element.current, "is-invalid");
    });

    setNameFieldErrorMessage("");
    setSurnameFieldErrorMessage("");
    setFullnameFieldErrorMessage("");
    setEmailFieldErrorMessage("");
    setBirthdayFieldErrorMessage("");
    setPhoneFieldErrorMessage("");
    setAvatarFieldErrorMessage("");

    setName(phonebookItem.name);
    setSurname(phonebookItem.surname);
    setEmail(phonebookItem.email);
    setPhoneNumber(phonebookItem.phonenumber);
    setEmail(phonebookItem.email);
    setBirthday(phonebookItem.birthday);

    authorInputRef.current.value = phonebookItem.author;

    toggleSpinnerOff();
  };

  const deleteRecord = async () => {
    toggleSpinnerOn();

    if (underJest()) {
      return;
    }

    await realtimeDB
      .ref("phonebook")
      .child(phonebookItem.id)
      .remove()
      .then(() => {
        return fileStorage.ref().child(phonebookItem.id).delete();
      })
      .then(() => {
        const updates = {};
        updates[`settings/recordsCount`] =
          firebaseInstance.database.ServerValue.increment(-1);
        return realtimeDB.ref().update(updates);
      })
      .then(() => {
        setTimeout(() => {
          const phonebookItemNew =
            phonebookFirebaseObject.getNewObject(currentUser);
          setPhonebookItem(phonebookItemNew);
          toggleSpinnerOff();
          history.push("/phonebook_list");
        }, 1000);
      })
      .catch((error) => {
        toggleSpinnerOff();
        setFormSubmitErrorMessage(error);
      });
  };

  const nameFieldValidator = (control) => {
    if (underJest()) {
      toggleSpinnerOn();
      return;
    }

    textValidator(
      2,
      30,
      control,
      namePassed,
      nameFailed,
      phonebookItem,
      "is-valid",
      "is-invalid"
    );
  };

  const namePassed = (object) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setPhonebookItem(phonebookItem);
    setNameFieldErrorMessage("");
    checkFullname();
    setName(phonebookItem.name);
  };

  const nameFailed = (object, errorMessage) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setPhonebookItem(phonebookItem);
    setNameFieldErrorMessage(errorMessage);
    setName(phonebookItem.name);
  };

  const checkFullname = async () => {
    const fullname = phonebookItem.name + phonebookItem.surname;
    const isFullnameRecordExists = await fullnameValidator(
      fullname,
      phonebookItem.id
    );

    if (!isFullnameRecordExists) {
      setFullnameFieldErrorMessage("");
      phonebookItem.fullname = fullname;
    } else {
      setFullnameFieldErrorMessage(
        `Record ${phonebookItem.name} ${phonebookItem.surname} exists. Please use other name`
      );
      phonebookItem.fullname = "";
    }
  };

  const surnameFieldValidator = (control) => {
    if (underJest()) {
      toggleSpinnerOn();
      return;
    }

    textValidator(
      3,
      30,
      control,
      surnamePassed,
      surnameFailed,
      phonebookItem,
      "is-valid",
      "is-invalid"
    );
  };

  const surnamePassed = (object) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setPhonebookItem(phonebookItem);
    setSurnameFieldErrorMessage("");
    checkFullname();
    setSurname(phonebookItem.surname);
  };

  const surnameFailed = (object, errorMessage) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setSurnameFieldErrorMessage(errorMessage);
    setSurname(phonebookItem.surname);
  };

  const emailFieldValidator = (control) => {
    if (underJest()) {
      toggleSpinnerOn();
      return;
    }

    emailValidator(
      5,
      64,
      control,
      emailPassed,
      emailFailed,
      phonebookItem,
      "is-valid",
      "is-invalid"
    );
  };

  const emailPassed = (object) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setPhonebookItem(phonebookItem);
    setEmailFieldErrorMessage("");
    setEmail(phonebookItem.email);
  };

  const emailFailed = (object, errorMessage) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setEmailFieldErrorMessage(errorMessage);
    setEmail(phonebookItem.email);
  };

  const phoneFieldValidator = (control) => {
    if (underJest()) {
      toggleSpinnerOn();
      return;
    }

    phoneValidator(
      control,
      phonePassed,
      phoneFailed,
      phonebookItem,
      "is-valid",
      "is-invalid"
    );
  };

  const phonePassed = (object, correctedValue) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setPhonebookItem(phonebookItem);
    setPhoneFieldErrorMessage("");
    setPhoneNumber(phonebookItem.phonenumber);
  };

  const phoneFailed = (object, errorMessage, correctedValue) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setPhoneFieldErrorMessage(errorMessage);
    setPhoneNumber(phonebookItem.phonenumber);
  };

  const birthdayFieldValidator = (control) => {
    if (underJest()) {
      toggleSpinnerOn();
      return;
    }

    birthdayValidator(
      control,
      birthdayPassed,
      birthdayFailed,
      phonebookItem,
      "is-valid",
      "is-invalid"
    );
  };

  const birthdayPassed = (object) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setPhonebookItem(phonebookItem);
    setBirthdayFieldErrorMessage("");
    setBirthday(phonebookItem.birthday);
  };

  const birthdayFailed = (object, errorMessage) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setBirthdayFieldErrorMessage(errorMessage);
    setBirthday(phonebookItem.birthday);
  };

  const avatarFieldValidator = (control) => {
    if (underJest()) {
      toggleSpinnerOn();
      return;
    }

    avatarValidator(
      control,
      avatarPassed,
      avatarFailed,
      phonebookItem,
      "is-valid",
      "is-invalid"
    );

    if (control && control.value && control.value.length > 0) {
      setAvatarFile(URL.createObjectURL(control.files[0]));
      setAvatarFileData(control.files[0]);
    }
  };

  const avatarPassed = (object) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setPhonebookItem(phonebookItem);
    setAvatarFieldErrorMessage("");
  };

  const avatarFailed = (object, errorMessage) => {
    setPhonebookItemIsValid(phonebookFirebaseObject.isValid(object));
    setAvatarFieldErrorMessage(errorMessage);
  };

  const clearAvatar = () => {
    setAvatarFile(process.env.PUBLIC_URL + "portrait_placeholder.jpg");
    avatarInputRef.current.value = "";
    phonebookItem.avatar = "";
    avatarFieldValidator(avatarInputRef.current);
  };

  return (
    <div className="container-fluid text-center mt-5 w-25">
      <form>
        <div className="form-group text-left">
          {cropMode ? (
            <div>
              <div className="cropperZone" style={{ position: "relative" }}>
                <Cropper
                  image={avatarFile}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
              <div className="text-center mb-3 mt-3">
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={updateAvatarWithCropedImage}
                >
                  Crop image
                </button>

                <button
                  type="button"
                  className="btn btn-warning btn-lg ml-3"
                  onClick={() => {
                    setCropMode(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="avatarZone">
                <img className="avatarImg" src={avatarFile} alt="avatar" />
              </div>
              <div className="text-center mb-3 mt-3">
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  disabled={phonebookItem.avatar.length === 0}
                  onClick={() => {
                    setCropMode(true);
                  }}
                >
                  Crop mode
                </button>
              </div>
            </div>
          )}

          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            placeholder="name"
            ref={nameInputRef}
            onChange={(event) => nameFieldValidator(event.target)}
          />
          {nameFieldErrorMessage.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              {nameFieldErrorMessage}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="form-group has-error text-left">
          <label htmlFor="surname">Surname</label>
          <input
            type="text"
            className="form-control"
            id="surname"
            value={surname}
            placeholder="surname"
            ref={surnameInputRef}
            onChange={(event) => surnameFieldValidator(event.target)}
          />
          {surnameFieldErrorMessage.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              {surnameFieldErrorMessage}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="form-group has-error text-left">
          {fullnameFieldErrorMessage.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              {fullnameFieldErrorMessage}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="form-group text-left">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            placeholder="name@example.com"
            ref={emailInputRef}
            onChange={(event) => emailFieldValidator(event.target)}
          />
          {emailFieldErrorMessage.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              {emailFieldErrorMessage}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="form-group text-left">
          <label htmlFor="phonenumberinput">Phone number</label>
          <input
            type="tel"
            className="form-control"
            id="phonenumber"
            placeholder="+380(XX)XXX-XX-XX"
            value={phoneNumber}
            ref={phonenumberInputRef}
            onChange={(event) => phoneFieldValidator(event.target)}
          />
          {phoneFieldErrorMessage.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              {phoneFieldErrorMessage}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="form-group text-left">
          <label htmlFor="birthdayinput">Birthday</label>
          <input
            type="date"
            className="form-control"
            id="birthday"
            value={birthday}
            ref={birthdayInputRef}
            onChange={(event) => birthdayFieldValidator(event.target)}
          />
          {birthdayFieldErrorMessage.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              {birthdayFieldErrorMessage}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="form-group text-left">
          <label htmlFor="avatarinput">Avatar</label>
          <div className="input-group">
            <input
              type="file"
              accept="image/*"
              className="form-control"
              id="avatar"
              ref={avatarInputRef}
              onChange={(event) => avatarFieldValidator(event.target)}
            />
            <div className="input-group-append">
              <button
                type="button"
                onClick={clearAvatar}
                className="btn btn-danger btn-sm btn-block"
              >
                Clear
              </button>
            </div>
          </div>
          {avatarFieldErrorMessage.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              {avatarFieldErrorMessage}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="form-group text-left">
          <label htmlFor="author">Author</label>
          <input
            type="author"
            className="form-control"
            id="author"
            ref={authorInputRef}
            placeholder="name@example.com"
            disabled
          />
        </div>
        <div className="form-group">
          {formSubmitSuccessMessage.length > 0 ? (
            <div className="alert alert-success mt-2" role="alert">
              {formSubmitSuccessMessage}
            </div>
          ) : (
            ""
          )}
          {formSubmitErrorMessage.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              {formSubmitErrorMessage}
            </div>
          ) : (
            ""
          )}
        </div>
        {formSubmitSuccessMessage.length === 0 ? (
          <div className="d-flex justify-content-end">
            <div className="mr-3">
              <button
                type="button"
                id="saveButton"
                onClick={submitForm}
                className="btn btn-primary btn-lg btn-block"
                disabled={!phonebookItemIsValid}
              >
                Save
              </button>
            </div>
            {phonebookItem.newrecord ? (
              <div className="mr-3">
                <button
                  type="button"
                  id="resetButton"
                  onClick={resetForm}
                  className="btn btn-warning btn-lg btn-block"
                >
                  Reset
                </button>
              </div>
            ) : (
              <div className="mr-3">
                <button
                  type="button"
                  id="deleteButton"
                  onClick={deleteRecord}
                  className="btn btn-danger btn-lg btn-block"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </form>
    </div>
  );
};

export default PhonebookRecord;
