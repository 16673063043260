import React from "react";
import PhonebookRecord from "../PhonebookRecord/PhonebookRecord";

const PhonebookEdit = () => {
  return (
    <div className="container-fluid text-center">
      <h2 id="title">Edit record</h2>
      <PhonebookRecord key={new Date().getTime()} />
    </div>
  );
};

export default PhonebookEdit;
