import React, { useContext, useEffect, useState } from "react";
import {
  SpinnerContext,
  underJest,
} from "../../utilites/Spinner/SpinnerContext";
import { realtimeDB } from "../../service/Firebase/Firebase";
import { getEmptyObject } from "../../service/Firebase/userPermissionsFirebaseObject";
import UsersItems from "../UsersItems/UsersItems";
import { NO_RECORDS_GRANT_PERMISSIONS } from "../../constants/constants";

const GrantPermissions = () => {
  const { toggleSpinnerOn, toggleSpinnerOff } = useContext(SpinnerContext);

  const noRecordsMessage = NO_RECORDS_GRANT_PERMISSIONS;

  const [records, setRecords] = React.useState([]);
  const [pageVersion, setPageVersion] = useState(0);

  useEffect(() => {
    toggleSpinnerOn();

    if (underJest()) {
      return;
    }

    async function getUsersForGrantPermissions() {
      const newRecords = [];

      if (underJest()) {
        return;
      }

      let newPortion = await realtimeDB
        .ref("requests")
        .orderByChild("nid")
        .get()
        .then(async function (dataSnapshot) {
          return await dataSnapshot.val();
        })
        .then(async (portion) => {
          if (!portion) {
            return [];
          }
          Object.keys(portion).forEach((key) => {
            let recordItem = Object.assign(getEmptyObject(), portion[key]);
            newRecords.push(recordItem);
          });

          return newRecords;
        });

      if (newPortion.length > 0) {
        setRecords(newPortion);
        toggleSpinnerOff();
      }

      return () => {
        setPageVersion(0);
        setRecords([]);
      };
    }

    getUsersForGrantPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageVersion]);

  const updateCallBack = () => {
    setPageVersion(pageVersion + 1);
  };

  return (
    <div>
      <div className="container-fluid text-center mt-5">
        <h2>Phonebook List</h2>
      </div>
      <div className="container mt-3">
        <div className="row">
          {records.length > 0
            ? records.map((element, index) => {
                return (
                  <div className="col-6 mt-3" key={index}>
                    <UsersItems
                      user={element}
                      updateCallback={updateCallBack}
                    />
                  </div>
                );
              })
            : noRecordsMessage}
        </div>
      </div>
    </div>
  );
};

export default GrantPermissions;
