export const LANDING = "/";
export const AUTH_SIGNIN = "/auth";
export const AUTH_SIGNOUT = "/signout";
export const AUTH_FORGOT_PASSWORD = "/auth/forgotpassword";
export const PHONEBOOK_ADD = "/phonebook_add";
export const PHONEBOOK_EDIT = "/phonebook_edit";
export const PHONEBOOK_SEARCH = "/phonebook_search";
export const PHONEBOOK_LIST = "/phonebook_list";
export const REQUEST_PERMISSIONS = "/request_permissions";
export const GRANT_PERMISSIONS = "/grant_permissions";
export const INIT_APPLICATION = "/init_application";
export const NEXT = "NEXT";
export const PREV = "PREV";
export const NO_RECORDS_GRANT_PERMISSIONS =
  "No users for granting permissions :(";
export const NO_RECORDS_MESSAGE = "We have no any records at this page :(";
export const ADD_ONE_MORE_LETTER_MESSAGE =
  "Search results are reached the limit for request. Please add additional letter for search name.";
export const RECORDS_PER_PAGE = 4;
