import React, { useState, useContext } from "react";
import { realtimeDB } from "../../service/Firebase/Firebase";
import {
  SpinnerContext,
  underJest,
} from "../../utilites/Spinner/SpinnerContext";

const UsersItems = (props) => {
  const user = props.user;

  const [recordUpdateSuccessMessage, setRecordUpdateSuccessMessage] =
    useState("");
  const [recordUpdateErrorMessage, setRecordUpdateErrorMessage] = useState("");
  const updateCallback = props.updateCallback;
  const { toggleSpinnerOn, toggleSpinnerOff } = useContext(SpinnerContext);

  const grantPermissions = (grant) => {
    user.granted = grant;
    updateUserPermissionRecord(user);
  };

  const updateUserPermissionRecord = async (userRecord) => {
    toggleSpinnerOn();

    if (underJest()) {
      return;
    }

    userRecord.processed = true;

    const updates = {};
    updates["/requests/" + userRecord.id] = userRecord;
    await realtimeDB
      .ref()
      .update(updates)
      .then(() => {
        updates["/users/" + userRecord.id] = userRecord;
        return realtimeDB.ref().update(updates);
      })
      .then(() => {
        console.info("user's record has been updated");
        setRecordUpdateSuccessMessage("record has been updated");
        toggleSpinnerOff();
        setTimeout(() => {
          setRecordUpdateSuccessMessage("");
        }, 5000);
      })
      .catch((error) => {
        console.info("ERROR user's record has been updated");
        setRecordUpdateErrorMessage("error");
        toggleSpinnerOff();
        setTimeout(() => {
          setRecordUpdateErrorMessage("");
        }, 5000);
      });

    updateCallback();
  };

  const cardTitleClasses = ["card-title"];

  if (user.processed) {
    cardTitleClasses.push("text-dark");
  } else {
    cardTitleClasses.push("text-info");
  }

  return (
    <div className="card border border-dark" key={user.id}>
      <div className="card-body text-center">
        <h5 className={cardTitleClasses.join(" ")}>{user.email}</h5>

        {user.granted ? (
          <button
            className="btn btn-danger m-1"
            id="grantPermissionsButton"
            onClick={() => {
              grantPermissions(false);
            }}
          >
            Grant
          </button>
        ) : (
          <button
            className="btn btn-success m-1"
            id="grantPermissionsButton"
            onClick={() => {
              grantPermissions(true);
            }}
          >
            Grant
          </button>
        )}
      </div>
      <div className="form-group">
        {recordUpdateSuccessMessage.length > 0 ? (
          <div className="alert alert-success mt-2" role="alert">
            {recordUpdateSuccessMessage}
          </div>
        ) : (
          ""
        )}
        {recordUpdateErrorMessage.length > 0 ? (
          <div className="alert alert-danger mt-2" role="alert">
            {recordUpdateErrorMessage}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UsersItems;
