import React, { useEffect, useState, useContext } from "react";
import {
  SpinnerContext,
  underJest,
} from "../../utilites/Spinner/SpinnerContext";
import {
  getNewObject,
  getEmptyObject,
} from "../../service/Firebase/userPermissionsFirebaseObject";
import { realtimeDB } from "../../service/Firebase/Firebase";

const RequestPermissions = () => {
  const permissionsHaveBeenRequested = "Permissions have been requested";
  const { toggleSpinnerOn, toggleSpinnerOff, currentUser } =
    useContext(SpinnerContext);
  const [permissionsRequested, setPermissonsRequested] = React.useState(false);
  const [userPermissionsRequestObject, setUserPermissionsRequestObject] =
    useState(getNewObject());

  useEffect(() => {
    async function getRequestPermissionsRecords() {
      toggleSpinnerOn();
      const newRecords = [];

      const newPortion = await realtimeDB
        .ref("requests")
        .orderByChild("email")
        .equalTo(currentUser)
        .get()
        .then(async function (dataSnapshot) {
          return await dataSnapshot.val();
        })
        .then(async (portion) => {
          if (!portion) {
            return [];
          }
          Object.keys(portion).forEach((key) => {
            let recordItem = Object.assign(getEmptyObject(), portion[key]);
            newRecords.push(recordItem);
          });

          if (newRecords.length > 0) {
            setUserPermissionsRequestObject(newRecords[0]);
          }

          return newRecords;
        });

      setPermissonsRequested(newPortion.length > 0 ? true : false);
      toggleSpinnerOff();
    }
    getRequestPermissionsRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsRequested]);

  const postRequestForPermissions = async () => {
    userPermissionsRequestObject.email = currentUser;

    toggleSpinnerOn();

    if (underJest()) {
      return;
    }

    await realtimeDB
      .ref("requests")
      .child(userPermissionsRequestObject.id)
      .set(userPermissionsRequestObject)
      .then(() => {
        setPermissonsRequested(true);
        toggleSpinnerOff();
      })
      .catch((error) => {
        setPermissonsRequested(false);
        toggleSpinnerOff();
      });
  };

  return (
    <div>
      <div className="container-fluid text-center mt-5 w-25">
        {permissionsRequested ? permissionsHaveBeenRequested : "rp false"}
        <br /> <br />
        <button
          type="button"
          className="btn btn-primary btn-lg btn-block"
          id="permissionsRequestButton"
          disabled={permissionsRequested}
          onClick={() => {
            postRequestForPermissions();
          }}
        >
          Request permissions
        </button>
      </div>
    </div>
  );
};

export default RequestPermissions;
