/* istanbul ignore file */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(config);
} else {
  console.info("firebase INIT skipped");
}

export const defaultUser = { loggedIn: false, email: "" };
export const firebaseAuth = firebase.auth();
export const realtimeDB = firebase.database();
export const fileStorage = firebase.storage();
export const firebaseInstance = firebase;

export const authenticateWithGoogle = () => {
  return firebase
    .auth()
    .signInWithPopup(new firebase.auth.GoogleAuthProvider());
};

export const signOut = async () => {
  return firebase.auth().signOut();
};
