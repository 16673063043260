import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { SpinnerContext } from "../../utilites/Spinner/SpinnerContext";

const SignIn = () => {
  const history = useHistory();
  const [loginErrorMessage, setLoginErrorMessage] = React.useState("");

  const { toggleSpinnerOn, toggleSpinnerOff } = useContext(SpinnerContext);

  const signInFunction = () => {
    toggleSpinnerOn();
    /* istanbul ignore next */
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((res) => {
        console.info("auth completed");
        toggleSpinnerOff();
        history.push("/");
      })
      .catch((error) => {
        // console.info(error);
        toggleSpinnerOff();
        setLoginErrorMessage(error);
      });
  };

  return (
    <div className="container-fluid text-center mt-5 w-25">
      <div>
        <button
          id="loginWithGoogleButton"
          type="button"
          className="btn btn-primary btn-lg btn-block"
          onClick={signInFunction}
        >
          Sign In with Google
        </button>
      </div>
      <div>
        {
          /* istanbul ignore next */
          loginErrorMessage.length > 0 ? (
            <div className="alert alert-error mt-2" role="alert">
              {loginErrorMessage}
            </div>
          ) : (
            ""
          )
        }
      </div>
    </div>
  );
};

export default SignIn;
