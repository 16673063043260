import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import * as CONSTANTS from "../../constants/constants";
import SignIn from "../SignIn/SignIn";
import SignOut from "../SignOut/SignOut";
import PhonebookAdd from "../PhonebookAdd/PhonebookAdd";
import PhonebookEdit from "../PhonebookEdit/PhonebookEdit";
import PhonebookList from "../PhonebookList/PhonebookList";
import PhonebookSearch from "../PhonebookSearch/PhonebookSearch";
import RequestPermissions from "../RequestPermissions/RequestPermissions";
import InitApplication from "../InitApplication/InitApplication";
import GrantPermissions from "../GrantPermissions/GrantPermissions";

const RoutesArray = (props) => {
  return (
    <Router>
      <Navigation
        permissionsGranted={props.permissionsGranted}
        currentUser={props.currentUser}
        isAdmin={props.isAdmin}
        initCompleted={props.initCompleted}
      />
      <hr />
      <Route path={CONSTANTS.AUTH_SIGNIN} exact>
        <SignIn />
      </Route>
      <Route path={CONSTANTS.AUTH_SIGNOUT}>
        <SignOut />
      </Route>
      <Route path={CONSTANTS.REQUEST_PERMISSIONS}>
        {props.authStatus ? <RequestPermissions /> : <Redirect to="/auth" />}
      </Route>
      <Route path={CONSTANTS.INIT_APPLICATION}>
        {props.authStatus ? <InitApplication /> : <Redirect to="/auth" />}
      </Route>

      <Route path={CONSTANTS.PHONEBOOK_LIST}>
        {props.authStatus ? <PhonebookList /> : <Redirect to="/auth" />}
      </Route>

      <Route path={CONSTANTS.PHONEBOOK_ADD}>
        {props.authStatus ? <PhonebookAdd /> : <Redirect to="/auth" />}
      </Route>

      <Route path={CONSTANTS.PHONEBOOK_EDIT}>
        {props.authStatus ? <PhonebookEdit /> : <Redirect to="/auth" />}
      </Route>

      <Route path={CONSTANTS.PHONEBOOK_SEARCH}>
        {props.authStatus ? <PhonebookSearch /> : <Redirect to="/auth" />}
      </Route>

      <Route path={CONSTANTS.GRANT_PERMISSIONS}>
        {props.authStatus ? <GrantPermissions /> : <Redirect to="/auth" />}
      </Route>
    </Router>
  );
};

export default RoutesArray;
