import React, { useEffect, useRef, useState, useContext } from "react";
import PhonebookItem from "../PhonebookItem/PhonebookItem";
import { realtimeDB } from "../../service/Firebase/Firebase";
import { getEmptyObject } from "../../service/Firebase/phonebookFirebaseObject";
import {
  NO_RECORDS_MESSAGE,
  ADD_ONE_MORE_LETTER_MESSAGE,
  RECORDS_PER_PAGE,
} from "../../constants/constants";
import {
  SpinnerContext,
  underJest,
} from "../../utilites/Spinner/SpinnerContext";

const PhonebookSearch = () => {
  const [records, setRecords] = React.useState([]);
  const [timerId, setTimerId] = useState(0);
  const [nameForSearch, setNameForSearch] = useState("");
  const { toggleSpinnerOn, toggleSpinnerOff } = useContext(SpinnerContext);

  const searchNameInputRef = useRef(null);
  const noRecordsMessage = NO_RECORDS_MESSAGE;
  const addOneMoreLetterMessage = ADD_ONE_MORE_LETTER_MESSAGE;

  const searchNameChanged = (control) => {
    console.info("search name: ", control.value);

    if (timerId) {
      clearTimeout(timerId);
    }

    const newTimerId = setTimeout(() => doNewSearch(control.value), 1000);
    setTimerId(newTimerId);
  };

  const doNewSearch = (searchName) => {
    setNameForSearch(searchName);
  };

  useEffect(() => {
    toggleSpinnerOn();

    if (underJest()) {
      return;
    }

    async function searchForRecords() {
      const newRecords = [];

      let newPortion = await realtimeDB
        .ref("phonebook")
        .orderByChild("fullname")
        .startAfter(nameForSearch)
        .endBefore(nameForSearch + "\uf8ff")
        .limitToFirst(RECORDS_PER_PAGE)
        .get()
        .then(async function (dataSnapshot) {
          return await dataSnapshot.val();
        })
        .then(async (portion) => {
          if (!portion || portion===null) {
            toggleSpinnerOff();
            return [];
          }
          Object.keys(portion).forEach((key) => {
            let recordItem = Object.assign(getEmptyObject(), portion[key], {
              newrecord: false,
              changed: false,
            });
            recordItem.avatar = recordItem.imageurl;
            newRecords.push(recordItem);
          });
          toggleSpinnerOff();
          return newRecords;
        })
        .catch((error) => {
          console.info("error: ", error);
          //to-do error message
          toggleSpinnerOff();
        });
      setRecords(newPortion);
    }

    if (nameForSearch !== undefined) {
      searchForRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameForSearch]);

  return (
    <div>
      <div>
        <div className="container-fluid text-center mt-5">Phonebook Search</div>
        <div className="container">
          <div className="form-group text-left">
            <label htmlFor="phonenumberinput">Name</label>
            <input
              type="tel"
              className="form-control"
              placeholder="name"
              ref={searchNameInputRef}
              onChange={(event) => searchNameChanged(event.target)}
            />
          </div>
          {records.length === RECORDS_PER_PAGE ? addOneMoreLetterMessage : ""}
        </div>
        <div className="container">
          <div className="row">
            {records.length > 0
              ? records.map((element, index) => {
                  return (
                    <div className="col-3" key={index}>
                      <PhonebookItem element={element} />
                    </div>
                  );
                })
              : noRecordsMessage}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhonebookSearch;
