import { realtimeDB } from "../Firebase/Firebase";

export const phonebookRecordObject = {
  id: "",
  name: "",
  surname: "",
  email: "",
  phonenumber: "",
  birthday: "",
  avatar: "",
  fullname: "",
  newrecord: true,
  imageurl: "",
  changed: false,
  author: "",
  nid: "",
  cropped: false,
};

const getObject = (newPhonebookItemObject) => {
  const objectId = realtimeDB.ref().child("phonebook/").push().key;
  return Object.assign(newPhonebookItemObject, { id: objectId });
};

export const getNewObject = () => {
  const newObject = getObject(Object.create(phonebookRecordObject));
  newObject.nid = new Date().getTime();
  newObject.name = "";
  newObject.surname = "";
  newObject.email = "";
  newObject.phonenumber = "";
  newObject.birthday = "";
  newObject.avatar = "";
  newObject.author = "";

  return newObject;
};

export const getEmptyObject = () => {
  return Object.create(phonebookRecordObject);
};

export function isValid(objectIn) {
  if (!objectIn.changed) {
    return false;
  }

  let result = true;
  Object.keys(objectIn).forEach((key) => {
    if (objectIn[key] === "") {
      result = false;
      return;
    }
  });

  if (result && objectIn.phonenumber.length !== 17) {
    result = false;
  }

  return result;
}
