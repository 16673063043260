import React, { useState, useEffect } from "react";
import { SpinnerContext } from "../../utilites/Spinner/SpinnerContext";
import * as phonebookFirebaseObject from "../../service/Firebase/phonebookFirebaseObject";
import RoutesArray from "../RoutesArray/RoutesArray";

const App = () => {
  useEffect(() => {
    document.title = "Phonebook";
  }, []);

  const toggleSpinnerOn = () => {
    setSpinnerStatus(true);
  };

  const toggleSpinnerOff = () => {
    setSpinnerStatus(false);
  };

  const [currentUser, setCurrentUser] = React.useState(
    window.sessionStorage.getItem("currentUser") || ""
  );

  const isAuth = () => {
    return currentUser !== null &&
      currentUser.length > 0 &&
      window.sessionStorage.getItem("currentUser") !== null
      ? true
      : false;
  };

  const [authStatus, setAuthStatus] = React.useState(isAuth());
  const [spinnerStatus, setSpinnerStatus] = React.useState(false);
  const [phonebookItem, setPhonebookItem] = React.useState(
    phonebookFirebaseObject.getNewObject(currentUser)
  );
  const [initCompleted, setInitCompleted] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [permissionsGranted, setPermissionsGranted] = useState(false);

  useEffect(() => {
    toggleSpinnerOn();
    setAuthStatus(isAuth());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <SpinnerContext.Provider
      value={{
        spinnerStatus,
        toggleSpinnerOn,
        toggleSpinnerOff,
        phonebookItem,
        setPhonebookItem,
        currentUser,
        setCurrentUser,
        authStatus,
        initCompleted,
        setInitCompleted,
        isAdmin,
        setIsAdmin,
        permissionsGranted,
        setPermissionsGranted,
      }}
    >
      {spinnerStatus ? (
        <div className="spinnerdiv">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}

      <RoutesArray
        permissionsGranted={permissionsGranted}
        currentUser={currentUser}
        isAdmin={isAdmin}
        initCompleted={initCompleted}
        authStatus={authStatus}
      />
    </SpinnerContext.Provider>
  );
};

export default App;
