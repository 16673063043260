import React, { useContext } from "react";
import {
  SpinnerContext,
  underJest,
} from "../../utilites/Spinner/SpinnerContext";
import { getNewObject } from "../../service/Firebase/userPermissionsFirebaseObject";
import { realtimeDB, firebaseInstance } from "../../service/Firebase/Firebase";
import { useHistory } from "react-router-dom";
import { getEmptyObject as getEmptyObjectIsAdmin } from "../../service/Firebase/adminFirebaseObject";

const InitApplication = () => {
  const history = useHistory();

  const {
    toggleSpinnerOn,
    toggleSpinnerOff,
    currentUser,
    initCompleted,
    setInitCompleted,
  } = useContext(SpinnerContext);

  const [initCompleteMessage, setInitCompleteMessage] = React.useState("");
  const [initCompleteErrorMessage, setInitCompleteErrorMessage] =
    React.useState("");

  const initApplicationProcedure = async () => {
    const firstAdminUser = getNewObject();
    firstAdminUser.email = currentUser;
    firstAdminUser.granted = true;

    const isAdmin = getEmptyObjectIsAdmin();
    isAdmin.isadmin = true;

    toggleSpinnerOn();

    if (underJest()) {
      return;
    }

    realtimeDB
      .ref("users")
      .child(firstAdminUser.id)
      .set(firstAdminUser)
      .then(() => {
        return realtimeDB
          .ref("admin")
          .child(window.sessionStorage.getItem("uid"))
          .set(isAdmin);
      })
      .then(() => {
        const updates = {};
        updates[`settings/initdone`] =
          firebaseInstance.database.ServerValue.increment(1);
        return realtimeDB.ref().update(updates);
      })
      .then(() => {
        setInitCompleteMessage("INIT has been saved");
        toggleSpinnerOff();
        setTimeout(() => {
          if (!initCompleted) {
            setInitCompleted(true);
          }
          history.push("/");
        }, 1000);
      })
      .catch((error) => {
        console.info("error: ", error);
        setInitCompleteErrorMessage(error.toString());
        toggleSpinnerOff();
      });
  };

  return (
    <div>
      <div className="container-fluid text-center mt-5 w-25">
        <br /> <br />
        <button
          type="button"
          className="btn btn-primary btn-lg btn-block"
          id="initApplicationButton"
          onClick={() => {
            initApplicationProcedure();
          }}
        >
          Init application
        </button>
        <div className="form-group">
          {initCompleteMessage.length > 0 ? (
            <div className="alert alert-success mt-2" role="alert">
              {initCompleteMessage}
            </div>
          ) : (
            ""
          )}
          {initCompleteErrorMessage.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              {initCompleteErrorMessage}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default InitApplication;
