import React from "react";
import PhonebookRecord from "../PhonebookRecord/PhonebookRecord";

const PhonebookAdd = () => {

  return (
    <div className="container-fluid text-center">
      <h2>Add record</h2>
      <PhonebookRecord key={new Date().getTime()} />
    </div>
  );
};

export default PhonebookAdd;
